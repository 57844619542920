<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row style="display: none;">
      <!--<v-col cols="12">-->
        <!--<base-material-card-->
          <!--icon="mdi-earth"-->
          <!--title="Global Sales by Top Locations"-->
        <!--&gt;-->
          <!--<v-row>-->
            <!--<v-col-->
              <!--cols="12"-->
              <!--md="6"-->
              <!--class="mt-10"-->
            <!--&gt;-->
              <!--<v-simple-table-->
                <!--class="ml-2"-->
              <!--&gt;-->
                <!--<tbody>-->
                  <!--<tr-->
                    <!--v-for="(sale, i) in sales"-->
                    <!--:key="i"-->
                  <!--&gt;-->
                    <!--<td>-->
                      <!--<v-img-->
                        <!--:src="sale.flag"-->
                        <!--width="18"-->
                      <!--/>-->
                    <!--</td>-->
                    <!--<td v-text="sale.country" />-->
                    <!--<td v-text="sale.salesInM" />-->
                    <!--<td v-text="((sale.salesInM / totalSales) * 100).toFixed(2) + '%'" />-->
                  <!--</tr>-->
                <!--</tbody>-->
              <!--</v-simple-table>-->
            <!--</v-col>-->

            <!--<v-col-->
              <!--cols="12"-->
              <!--md="6"-->
            <!--&gt;-->
              <!--<v-world-map-->
                <!--:country-data="countryData"-->
                <!--high-color="#838383"-->
                <!--low-color="#BBBBBB"-->
              <!--/>-->
            <!--</v-col>-->
          <!--</v-row>-->
        <!--</base-material-card>-->
      <!--</v-col>-->

      <!--<v-col-->
        <!--cols="12"-->
        <!--lg="4"-->
      <!--&gt;-->
        <!--<base-material-chart-card-->
          <!--:data="emailsSubscriptionChart.data"-->
          <!--:options="emailsSubscriptionChart.options"-->
          <!--:responsive-options="emailsSubscriptionChart.responsiveOptions"-->
          <!--color="primary"-->
          <!--hover-reveal-->
          <!--type="Bar"-->
        <!--&gt;-->
          <!--<template v-slot:reveal-actions>-->
            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--v-bind="attrs"-->
                  <!--color="info"-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon-->
                    <!--color="info"-->
                  <!--&gt;-->
                    <!--mdi-refresh-->
                  <!--</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>Refresh</span>-->
            <!--</v-tooltip>-->

            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--v-bind="attrs"-->
                  <!--light-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon>mdi-pencil</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>Change Date</span>-->
            <!--</v-tooltip>-->
          <!--</template>-->

          <!--<h4 class="card-title font-weight-light mt-2 ml-2">-->
            <!--Website Views-->
          <!--</h4>-->

          <!--<p class="d-inline-flex font-weight-light ml-2 mt-1">-->
            <!--Last Campaign Performance-->
          <!--</p>-->

          <!--<template v-slot:actions>-->
            <!--<v-icon-->
              <!--class="mr-1"-->
              <!--small-->
            <!--&gt;-->
              <!--mdi-clock-outline-->
            <!--</v-icon>-->
            <!--<span class="caption grey&#45;&#45;text font-weight-light">updated 10 minutes ago</span>-->
          <!--</template>-->
        <!--</base-material-chart-card>-->
      <!--</v-col>-->

      <!--<v-col-->
        <!--cols="12"-->
        <!--lg="4"-->
      <!--&gt;-->
        <!--<base-material-chart-card-->
          <!--:data="dailySalesChart.data"-->
          <!--:options="dailySalesChart.options"-->
          <!--color="success"-->
          <!--hover-reveal-->
          <!--type="Line"-->
        <!--&gt;-->
          <!--<template v-slot:reveal-actions>-->
            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--v-bind="attrs"-->
                  <!--color="info"-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon-->
                    <!--color="info"-->
                  <!--&gt;-->
                    <!--mdi-refresh-->
                  <!--</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>Refresh</span>-->
            <!--</v-tooltip>-->

            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--v-bind="attrs"-->
                  <!--light-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon>mdi-pencil</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>Change Date</span>-->
            <!--</v-tooltip>-->
          <!--</template>-->

          <!--<h4 class="card-title font-weight-light mt-2 ml-2">-->
            <!--Daily Sales-->
          <!--</h4>-->

          <!--<p class="d-inline-flex font-weight-light ml-2 mt-1">-->
            <!--<v-icon-->
              <!--color="green"-->
              <!--small-->
            <!--&gt;-->
              <!--mdi-arrow-up-->
            <!--</v-icon>-->
            <!--<span class="green&#45;&#45;text">55%</span>&nbsp;-->
            <!--increase in today's sales-->
          <!--</p>-->

          <!--<template v-slot:actions>-->
            <!--<v-icon-->
              <!--class="mr-1"-->
              <!--small-->
            <!--&gt;-->
              <!--mdi-clock-outline-->
            <!--</v-icon>-->
            <!--<span class="caption grey&#45;&#45;text font-weight-light">updated 4 minutes ago</span>-->
          <!--</template>-->
        <!--</base-material-chart-card>-->
      <!--</v-col>-->

      <!--<v-col-->
        <!--cols="12"-->
        <!--lg="4"-->
      <!--&gt;-->
        <!--<base-material-chart-card-->
          <!--:data="dataCompletedTasksChart.data"-->
          <!--:options="dataCompletedTasksChart.options"-->
          <!--hover-reveal-->
          <!--color="info"-->
          <!--type="Line"-->
        <!--&gt;-->
          <!--<template v-slot:reveal-actions>-->
            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--v-bind="attrs"-->
                  <!--color="info"-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon-->
                    <!--color="info"-->
                  <!--&gt;-->
                    <!--mdi-refresh-->
                  <!--</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>Refresh</span>-->
            <!--</v-tooltip>-->

            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--v-bind="attrs"-->
                  <!--light-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon>mdi-pencil</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>Change Date</span>-->
            <!--</v-tooltip>-->
          <!--</template>-->

          <!--<h3 class="card-title font-weight-light mt-2 ml-2">-->
            <!--Completed Tasks-->
          <!--</h3>-->

          <!--<p class="d-inline-flex font-weight-light ml-2 mt-1">-->
            <!--Last Last Campaign Performance-->
          <!--</p>-->

          <!--<template v-slot:actions>-->
            <!--<v-icon-->
              <!--class="mr-1"-->
              <!--small-->
            <!--&gt;-->
              <!--mdi-clock-outline-->
            <!--</v-icon>-->
            <!--<span class="caption grey&#45;&#45;text font-weight-light">campaign sent 26 minutes ago</span>-->
          <!--</template>-->
        <!--</base-material-chart-card>-->
      <!--</v-col>-->

      <!--<v-col-->
        <!--cols="12"-->
        <!--sm="6"-->
        <!--lg="3"-->
      <!--&gt;-->
        <!--<base-material-stats-card-->
          <!--color="info"-->
          <!--icon="mdi-twitter"-->
          <!--title="Followers"-->
          <!--value="+245"-->
          <!--sub-icon="mdi-clock"-->
          <!--sub-text="Just Updated"-->
        <!--/>-->
      <!--</v-col>-->

      <!--<v-col-->
        <!--cols="12"-->
        <!--sm="6"-->
        <!--lg="3"-->
      <!--&gt;-->
        <!--<base-material-stats-card-->
          <!--color="primary"-->
          <!--icon="mdi-poll"-->
          <!--title="Website Visits"-->
          <!--value="75.521"-->
          <!--sub-icon="mdi-tag"-->
          <!--sub-text="Tracked from Google Analytics"-->
        <!--/>-->
      <!--</v-col>-->

      <!--<v-col-->
        <!--cols="12"-->
        <!--sm="6"-->
        <!--lg="3"-->
      <!--&gt;-->
        <!--<base-material-stats-card-->
          <!--color="success"-->
          <!--icon="mdi-store"-->
          <!--title="Revenue"-->
          <!--value="$ 34,245"-->
          <!--sub-icon="mdi-calendar"-->
          <!--sub-text="Last 24 Hours"-->
        <!--/>-->
      <!--</v-col>-->

      <!--<v-col-->
        <!--cols="12"-->
        <!--sm="6"-->
        <!--lg="3"-->
      <!--&gt;-->
        <!--<base-material-stats-card-->
          <!--color="orange"-->
          <!--icon="mdi-sofa"-->
          <!--title="Bookings"-->
          <!--value="184"-->
          <!--sub-icon="mdi-alert"-->
          <!--sub-icon-color="red"-->
          <!--sub-text="Get More Space..."-->
        <!--/>-->
      <!--</v-col>-->

      <!--<v-col-->
        <!--cols="12"-->
      <!--&gt;-->
        <!--<div-->
          <!--class="font-weight-light mt-1"-->
          <!--style="color:#3c4858; font-size: 25px"-->
        <!--&gt;-->
          <!--Manage Listings-->
        <!--</div>-->
      <!--</v-col>-->

      <!--<v-col-->
        <!--sm="12"-->
        <!--md="4"-->
      <!--&gt;-->
        <!--<base-material-card-->
          <!--color="transparent"-->
          <!--image-->
          <!--hover-reveal-->
        <!--&gt;-->
          <!--<template v-slot:image>-->
            <!--<v-img-->
              <!--src="https://demos.creative-tim.com/vue-material-dashboard-pro/img/card-2.jpg"-->
            <!--/>-->
          <!--</template>-->

          <!--<template v-slot:reveal-actions>-->
            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--class="mx-1"-->
                  <!--v-bind="attrs"-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon>mdi-view-split-vertical</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>View</span>-->
            <!--</v-tooltip>-->

            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--v-bind="attrs"-->
                  <!--class="mx-1"-->
                  <!--color="success"-->
                  <!--light-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon class="success&#45;&#45;text">-->
                    <!--mdi-pencil-->
                  <!--</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>Edit</span>-->
            <!--</v-tooltip>-->

            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--v-bind="attrs"-->
                  <!--class="mx-1"-->
                  <!--color="error"-->
                  <!--light-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon class="error&#45;&#45;text">-->
                    <!--mdi-close-->
                  <!--</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>Remove</span>-->
            <!--</v-tooltip>-->
          <!--</template>-->

          <!--<v-card-title class="justify-center font-weight-light">-->
            <!--Cozy 5 Stars Apartment-->
          <!--</v-card-title>-->

          <!--<v-card-text class="body-1 text-center mb-3 font-weight-light grey&#45;&#45;text">-->
            <!--The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the life in Barcelona.-->
          <!--</v-card-text>-->

          <!--<template v-slot:actions>-->
            <!--<div class="display-2 font-weight-light grey&#45;&#45;text">-->
              <!--$899/night-->
            <!--</div>-->

            <!--<v-spacer />-->

            <!--<span class="caption grey&#45;&#45;text font-weight-light">-->
              <!--<v-icon small>mdi-map-marker</v-icon>-->

              <!--Barcelona, Spain-->
            <!--</span>-->
          <!--</template>-->
        <!--</base-material-card>-->
      <!--</v-col>-->

      <!--<v-col-->
        <!--sm="12"-->
        <!--md="4"-->
      <!--&gt;-->
        <!--<base-material-card-->
          <!--color="transparent"-->
          <!--hover-reveal-->
          <!--image-->
        <!--&gt;-->
          <!--<template v-slot:image>-->
            <!--<v-img-->
              <!--src="https://demos.creative-tim.com/vue-material-dashboard-pro/img/card-3.jpg"-->
            <!--/>-->
          <!--</template>-->

          <!--<template v-slot:reveal-actions>-->
            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--class="mx-1"-->
                  <!--v-bind="attrs"-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon>mdi-view-split-vertical</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>View</span>-->
            <!--</v-tooltip>-->

            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--v-bind="attrs"-->
                  <!--class="mx-1"-->
                  <!--color="success"-->
                  <!--light-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon class="success&#45;&#45;text">-->
                    <!--mdi-pencil-->
                  <!--</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>Edit</span>-->
            <!--</v-tooltip>-->

            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--v-bind="attrs"-->
                  <!--class="mx-1"-->
                  <!--color="error"-->
                  <!--light-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon class="error&#45;&#45;text">-->
                    <!--mdi-close-->
                  <!--</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>Remove</span>-->
            <!--</v-tooltip>-->
          <!--</template>-->

          <!--<v-card-title class="justify-center font-weight-light">-->
            <!--Office Studio-->
          <!--</v-card-title>-->

          <!--<v-card-text class="body-1 text-center mb-3 font-weight-light grey&#45;&#45;text">-->
            <!--The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the night life in London, UK.-->
          <!--</v-card-text>-->

          <!--<template v-slot:actions>-->
            <!--<div class="display-2 font-weight-light grey&#45;&#45;text">-->
              <!--$1.119/night-->
            <!--</div>-->

            <!--<v-spacer />-->

            <!--<span class="caption grey&#45;&#45;text font-weight-light">-->
              <!--<v-icon small>mdi-map-marker</v-icon>-->

              <!--London, UK-->
            <!--</span>-->
          <!--</template>-->
        <!--</base-material-card>-->
      <!--</v-col>-->

      <!--<v-col-->
        <!--sm="12"-->
        <!--md="4"-->
      <!--&gt;-->
        <!--<base-material-card-->
          <!--color="transparent"-->
          <!--hover-reveal-->
          <!--image-->
        <!--&gt;-->
          <!--<template v-slot:image>-->
            <!--<v-img-->
              <!--src="https://demos.creative-tim.com/vue-material-dashboard-pro/img/card-1.jpg"-->
            <!--/>-->
          <!--</template>-->

          <!--<template v-slot:reveal-actions>-->
            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--class="mx-1"-->
                  <!--v-bind="attrs"-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon>mdi-view-split-vertical</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>View</span>-->
            <!--</v-tooltip>-->

            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--v-bind="attrs"-->
                  <!--class="mx-1"-->
                  <!--color="success"-->
                  <!--light-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon class="success&#45;&#45;text">-->
                    <!--mdi-pencil-->
                  <!--</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>Edit</span>-->
            <!--</v-tooltip>-->

            <!--<v-tooltip bottom>-->
              <!--<template v-slot:activator="{ attrs, on }">-->
                <!--<v-btn-->
                  <!--v-bind="attrs"-->
                  <!--class="mx-1"-->
                  <!--color="error"-->
                  <!--light-->
                  <!--icon-->
                  <!--v-on="on"-->
                <!--&gt;-->
                  <!--<v-icon class="error&#45;&#45;text">-->
                    <!--mdi-close-->
                  <!--</v-icon>-->
                <!--</v-btn>-->
              <!--</template>-->

              <!--<span>Remove</span>-->
            <!--</v-tooltip>-->
          <!--</template>-->

          <!--<v-card-title class="justify-center font-weight-light">-->
            <!--Beautiful Castle-->
          <!--</v-card-title>-->

          <!--<v-card-text class="body-1 text-center mb-3 font-weight-light grey&#45;&#45;text">-->
            <!--The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Milan.-->
          <!--</v-card-text>-->

          <!--<template v-slot:actions>-->
            <!--<div class="display-2 font-weight-light grey&#45;&#45;text">-->
              <!--$459/night-->
            <!--</div>-->

            <!--<v-spacer />-->

            <!--<span class="caption grey&#45;&#45;text font-weight-light">-->
              <!--<v-icon small>mdi-map-marker</v-icon>-->

              <!--Milan, Italy-->
            <!--</span>-->
          <!--</template>-->
        <!--</base-material-card>-->
      <!--</v-col>-->
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'DashboardDashboard',

    data() {
      return {
        countryData: {
          US: 2920,
          DE: 1390,
          AU: 760,
          GB: 690,
          RO: 600,
          BR: 550
        },
        dailySalesChart: {
          data: {
            labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            series: [
              [12, 17, 7, 17, 23, 18, 38]
            ]
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0
            }),
            low: 0,
            high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }
          }
        },
        dataCompletedTasksChart: {
          data: {
            labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
            series: [
              [230, 750, 450, 300, 280, 240, 200, 190]
            ]
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0
            }),
            low: 0,
            high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }
          }
        },
        emailsSubscriptionChart: {
          data: {
            labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
            series: [
              [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]

            ]
          },
          options: {
            axisX: {
              showGrid: false
            },
            low: 0,
            high: 1000,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0
            }
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0]
                }
              }
            }]
          ]
        },
        headers: [
          {
            sortable: false,
            text: 'ID',
            value: 'id'
          },
          {
            sortable: false,
            text: 'Name',
            value: 'name'
          },
          {
            sortable: false,
            text: 'Salary',
            value: 'salary',
            align: 'right'
          },
          {
            sortable: false,
            text: 'Country',
            value: 'country',
            align: 'right'
          },
          {
            sortable: false,
            text: 'City',
            value: 'city',
            align: 'right'
          }
        ],
        items: [
          {
            id: 1,
            name: 'Dakota Rice',
            country: 'Niger',
            city: 'Oud-Tunrhout',
            salary: '$35,738'
          },
          {
            id: 2,
            name: 'Minerva Hooper',
            country: 'Curaçao',
            city: 'Sinaai-Waas',
            salary: '$23,738'
          },
          {
            id: 3,
            name: 'Sage Rodriguez',
            country: 'Netherlands',
            city: 'Overland Park',
            salary: '$56,142'
          },
          {
            id: 4,
            name: 'Philip Chanley',
            country: 'Korea, South',
            city: 'Gloucester',
            salary: '$38,735'
          },
          {
            id: 5,
            name: 'Doris Greene',
            country: 'Malawi',
            city: 'Feldkirchen in Kārnten',
            salary: '$63,542'
          }
        ],
        sales: [
          {
            country: 'USA',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/US.png',
            salesInM: 2920
          },
          {
            country: 'Germany',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/DE.png',
            salesInM: 1300
          },
          {
            country: 'Australia',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/AU.png',
            salesInM: 760
          },
          {
            country: 'United Kingdom',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/GB.png',
            salesInM: 690
          },
          {
            country: 'Romania',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/RO.png',
            salesInM: 600
          },
          {
            country: 'Brasil',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/BR.png',
            salesInM: 550
          }
        ],
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false
        }
      }
    },

    computed: {
      totalSales() {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      }
    },


    methods: {
      complete(index) {
        this.list[index] = !this.list[index]
      }
    }

  }
</script>

<style lang="scss">
.v-sheet {
  border-radius: 4px;
}
</style>
